import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import EditableNavLink from 'components/basepaws/utils/EditableNavLink'

import { IonRow, IonCol, IonList, IonItem, IonInput, IonLabel, IonIcon, IonListHeader, IonButton } from '@ionic/react'
import { closeCircle, eye, eyeOff, logoFacebook, logoGoogle } from 'ionicons/icons'
import LoaderButton from 'components/basepaws/utils/LoaderButton/index'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'

import { Formik } from 'formik'
import * as Yup from 'yup'
import services from 'util/services'
import content from 'util/content'
import styles from './LoginForm.module.scss'

const LoginForm = () => {
  const [passwordFieldType, setPasswordFieldType] = useState({
    passwordType: 'password',
    passwordIcon: eye
  })

  const history = useHistory()

  const onSubmit = async (values, setSubmitting) => {
    setLoginFailed(false)
    localStorage.removeItem('user')
    try {
      await services.login(values)
      const user = await services.user() // broken 'cause of org portal
      if (user.id) setSubmitting(false)
      history.push('/profile')
    } catch (e) {
      console.log(e)
      setSubmitting(false)
      setLoginFailed(true)
    }
  }

  const [isLoginFailed, setLoginFailed] = useState(false)

  const changeType = () => {  
  const currentType = passwordFieldType.passwordType;
  let nextType = 'password';
  let icon = eye;
  if (currentType === 'password') {
      nextType = 'text';
      icon = eyeOff;
  }
  setPasswordFieldType({
      ...passwordFieldType,
      passwordType: nextType,
      passwordIcon: icon
  });
};

  const registerSchema = Yup.object().shape()
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={registerSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        resetForm,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12">
              <div className={styles.social_media_wrapper}>
                {/* <EditableIonButton
                  mode="md"
                  color="primary"
                  size="full"
                  fill="outline"
                  shape="round"
                  className="default_button"
                  // href="/2020-09/auth/facebook"
                  onClick={() => { window.location.href = '/2020-09/auth/facebook' }}
                >
                  <IonIcon
                    slot="start"
                    icon={logoFacebook}
                  >
                  </IonIcon>
                  {content.get('LOGIN.CONNECT.FACEBOOK')}
                </EditableIonButton> */}

                <IonButton
                  mode="md"
                  color="primary"
                  size="full"
                  fill="outline"
                  shape="round"
                  className="default_button"
                  href="/2020-09/auth/facebook"
                >
                  <IonIcon
                    slot="start"
                    icon={logoFacebook}
                  >
                  </IonIcon>
                  {content.get('LOGIN.CONNECT.FACEBOOK')}
                </IonButton>

                {/* <EditableIonButton
                  mode="md"
                  color="tertiary"
                  size="full"
                  fill="outline"
                  shape="round"
                  className="default_button"
                  href="/2020-09/auth/google"
                >
                  <IonIcon
                    slot="start"
                    icon={logoGoogle}
                  >
                  </IonIcon>
                  {content.get('LOGIN.CONNECT.GOOGLE')}
                </EditableIonButton> */}

                <IonButton
                  mode="md"
                  color="tertiary"
                  size="full"
                  fill="outline"
                  shape="round"
                  className="default_button"
                  href="/2020-09/auth/google"
                >
                  <IonIcon
                    slot="start"
                    icon={logoGoogle}
                  >
                  </IonIcon>
                  {content.get('LOGIN.CONNECT.GOOGLE')}
                </IonButton>
                <div className="d-flex ion-justify-content-center">
                  <hr/>
                  <p className="Body_1">{content.get('LOGIN.CONNECT.OR_MESSAGE')}</p>
                  <hr/>
                </div>
              </div>
              <IonList className="form">
                <IonItem lines="none" className="item-no-padding">
                  <IonListHeader className="ion-no-padding">
                    <IonLabel>
                      <h4 className="Heading_5 text_sad_purple">{content.get('LOGIN.FIELDS.EMAIL')}</h4>
                    </IonLabel>
                  </IonListHeader>
                </IonItem>
                <IonItem lines="none" className="item-no-padding">
                  <IonInput
                    type="email"
                    name="email"
                    onIonChange={handleChange}
                    onIonInput={handleChange}
                    value={values.email}
                    className={isLoginFailed === true ? 'has-error' : null}
                  >
                  </IonInput>
                      {errors.born_on && touched.born_on
                        ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label">
                            <IonIcon
                              icon={closeCircle}
                              color="danger"
                              slot="start"
                            />
                            {errors.born_on}
                          </div>
                        </IonItem>
                          )
                        : null}
                </IonItem>
                <IonItem lines="none" className="item-no-padding">
                  <IonListHeader className="ion-no-padding">
                    <IonLabel>
                      <h4 className="Heading_5 text_sad_purple">{content.get('LOGIN.FIELDS.PASSWORD')}</h4>
                    </IonLabel>
                  </IonListHeader>
                </IonItem>
                <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                <IonInput
                  className={`input-w-icon ${isLoginFailed === true ? 'has-error' : null}`}
                  type={passwordFieldType.passwordType}
                  name="password"
                  onIonChange={handleChange}
                  onIonInput={handleChange}
                  clearOnEdit={false}
                  value={values.password}
                  />
                  <IonButton
                    mode="md"
                    fill="clear"
                    tabindex="-1"
                    onClick={(e) => changeType(e)}
                    style={{ 
                      position: 'absolute',
                      right: '10px', 
                      zIndex: 1000
                    }} 
                  >
                    <IonIcon
                      slot="icon-only"
                      color="dark"
                      icon={passwordFieldType.passwordIcon}
                    />
                  </IonButton>
                      {errors.currentPassword && touched.currentPassword
                        ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label">
                            <IonIcon
                              icon={closeCircle}
                              color="danger"
                              slot="start"
                            />
                            {errors.born_on}
                          </div>
                        </IonItem>
                          )
                        : null}
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
              {isLoginFailed === true &&
                <IonItem className="ion-margin-bottom item-no-padding input-error-message">
                  <IonRow>
                    <IonCol>
                      <div className="input-error-label">
                        <IonIcon icon={closeCircle} color="danger" slot="start"/>
                        {content.get('LOGIN.FIELDS.ERROR')}
                      </div>
                    </IonCol>
                  </IonRow>
              </IonItem>
              }
          <div className={`${styles.card_footer} ion-justify-content-center`}>
            <LoaderButton
              type="submit"
              disabled={isSubmitting || values.password === '' || values.email === ''}
              color="primary"
              className="big_button loader_button"
              isSubmitting={isSubmitting}
            >
              {content.get('LOGIN.FIELDS.SUBMIT_TEXT')}
            </LoaderButton>
          </div>
          <p className="ion-text-center Body_1">
            {content.get('LOGIN.FORGOT_PASSWORD_TEXT')}
            <EditableNavLink
              to="/recoverPasswordEmail"
              className="ml-2"
            >
              {content.get('LOGIN.FORGOT_PASSWORD_CTA')}
            </EditableNavLink>
          </p>
          <hr className={styles.divider}/>
          <p className="ion-text-center Body_1">
            {content.get('LOGIN.NEW_ACCOUNT_TEXT')}
            <EditableNavLink
              to="/register"
              className="ml-2"
            >
            {content.get('LOGIN.NEW_ACCOUNT_CTA')}
            </EditableNavLink>
          </p>
          <p className="ion-text-center Body_2 ion-margin-top ion-padding-top text_dark_grey">
            {content.get('LOGIN.TOS_PP')}
            <a href="https://basepaws.com/pages/terms-of-service" target="_blank" className="ml-2 Body_2_bold" rel="noreferrer">{content.get('REGISTER.FORM.TERMS_LINK_TEXT')}</a>
            {' and'}
            <a href="https://basepaws.com/pages/privacy-policy" target="_blank" className="ml-2 Body_2_bold" rel="noreferrer">{content.get('REGISTER.FORM.TERMS_LINK_TEXT2')}</a>
          </p>
        </form>
      )}
    </Formik>
  )
}

export default LoginForm
