import React, { useEffect, useState } from 'react'

import {
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonGrid,
  IonIcon,
  IonCardHeader,
  IonAvatar,
  IonCardContent,
  IonRadioGroup,
  IonRadio,
  IonTextarea,
  IonDatetime,
  isPlatform,
  useIonViewDidLeave,
  useIonViewWillEnter
} from '@ionic/react'
import { closeCircle, camera } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'

import DateInputDesktop from 'components/basepaws/general/DateInputDesktop'
import Toaster from 'components/basepaws/utils/Toaster'
import LoaderButton from 'components/basepaws/utils/LoaderButton/index'
import Select from 'components/basepaws/utils/InputSelect'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import { adoptedOptions } from 'components/basepaws/account/OrderListItem/constants'
import content from 'util/content/'
import services from 'util/services'

import styles from './CatForm.module.scss'

const CatForm = (props) => {
  const { patient, newPet, setSelection } = props
  const [imageSrc, setImageSrc] = useState(
    services.thumb(
      patient?.images?.find((i) => i.hit === 0),
      patient?.organism
    )
  )
  const registerSchema = Yup.object().shape({
    gender: Yup.string().required('Please select a gender')
  })

  const desktop = isPlatform('desktop')
  const message = `Your pet was ${newPet ? 'created' : 'edited'}`

  const [edited, setEdited] = useState(false)
  let submittingProfilePic = false
  const [isChanged, setIsChanged] = useState(false)
  const [loadingProfilePic, setLoadingProfilePic] = useState(false)
  const history = useHistory()

  const [loadedFromState, setLoadedFromState] = useState(false)

  const [countrySelector, setCountrySelector] = useState(null)
  const [stateSelector, setStateSelector] = useState(null)
  const [citySelector, setCitySelector] = useState(null)

  const [countryList, setCountries] = useState([])
  const defineCountryList = async () => {
    try {
      setCountries(await services.getCountries())
    } catch (e) {
      console.log(e)
    }
  }

  useIonViewWillEnter(() => {
    setImageSrc(
      services.thumb(
        patient?.images?.find((i) => i.hit === 0),
        patient?.organism
      )
    )
  })

  const resetFormExternal = () => {
    setImageSrc(services.DEFAULT_CAT_PLACEHOLDER)
    setCitySelector(null)
    setStateSelector(null)
    setCountrySelector(null)
    setLoadingProfilePic(false)
  }

  useIonViewDidLeave(() => {
    resetFormExternal()
  })

  const onSubmitPP = () => {
    submittingProfilePic = true
  }

  useEffect(async () => {
    defineCountryList()
    if (patient) {
      patient.born_on = patient.born_on ? new Date(patient.born_on) : null
    }
  }, [])

  const [stateList, setStates] = useState([])
  const defineStateList = async (country) => {
    try {
      setStates(await services.getStates(country))
    } catch (e) {
      console.log(e)
    }
  }

  const [cityList, setCities] = useState([])
  const defineCityList = async (country, state) => {
    try {
      setCities(await services.getCities(country, state))
    } catch (e) {
      console.log(e)
    }
  }

  const navigateAndResetForm = (path, resetForm) => {
    setSelection && setSelection(null)
    history.push(path)
    resetForm()
  }

  const onSubmit = async (values, setSubmitting, resetForm) => {
    const birthLocation = {
      country: values.country ?? null,
      state: values.state ?? null,
      city: values.city ?? null,
      adopted: values.adopted ?? null
    }

    const pet = {
      ...patient,
      id: values.id ?? null,
      profilePic: values.profilePic,
      name: values.name,
      gender: values.gender,
      born_on: values.born_on,
      description: values.description,
      birth_location: birthLocation
    }

    let patientLocal = null

    if (newPet) {
      patientLocal = await services.addPet(pet)
      const handleLocal = `/pet/new/confirmation/${patientLocal.handle}`
      if (!submittingProfilePic) {
        navigateAndResetForm(handleLocal, resetForm)
      }
    } else {
      patientLocal = pet
      await services.setPets(patientLocal)
      const handleLocal = `/pets/${patientLocal.handle}`
      if (!submittingProfilePic) {
        navigateAndResetForm(handleLocal, resetForm)
      }
      setEdited(true)
    }
    if (!submittingProfilePic) setIsChanged(false)
    if (newPet) submittingProfilePic = false
    setSubmitting(false)
  }

  useEffect(() => {
    if (patient?.birth_location?.country && !loadedFromState) {
      const location = patient?.birth_location
      defineStateList(location.country)
      if (location.country) {
        const country = countryList.find((v) => v.id === location.country)
        setCountrySelector(country)
      }
    }
  }, [patient])

  useEffect(() => {
    if (patient?.birth_location?.state && !loadedFromState) {
      const location = patient?.birth_location
      defineCityList(location.country, location.state)
      if (location.state) {
        const state = stateList.find((v) => v.id === location.state)
        setStateSelector(state)
      }
    }
  }, [stateList])

  useEffect(() => {
    if (patient?.birth_location?.city && !loadedFromState) {
      const location = patient.birth_location
      if (location.city) {
        const city = cityList.find((v) => v.id === location.city)
        setCitySelector(city)
        setLoadedFromState(true)
      }
    }
  }, [cityList])

  useEffect(
    (prevPet) => {
      if (
        !(
          countrySelector?.id !== undefined &&
          patient?.birth_location?.country === countrySelector?.id
        )
      ) {
        if (prevPet?.updated_at !== patient?.updated_at) {
          setLoadedFromState(false)
        }
        if (patient?.birth_location?.country && !loadedFromState) {
          const location = patient?.birth_location
          defineStateList(location.country)
          if (location.country) {
            const country = countryList.find((v) => v.id === location.country)
            setCountrySelector(country)
          }
        }
      }
    },
    [patient, loadedFromState]
  )

  return (
    <>
      <Toaster
        status={edited}
        onDidDismiss={() => {
          setEdited(false)
          if (submittingProfilePic) {
            submittingProfilePic = false
          }
        }}
        header={content.get('CAT_FORM.FORM.TOAST.TITLE')}
        message={message}
      />
      <Formik
        enableReinitialize={true}
        initialValues={{
          id: patient?.id,
          name: patient?.name,
          gender: patient?.gender,
          born_on: patient?.born_on,
          description: patient?.description,
          country: patient?.birth_location?.country,
          state: patient?.birth_location?.state,
          city: patient?.birth_location?.city,
          adopted: patient?.birth_location?.adopted,
          profilePic: ''
        }}
        validationSchema={registerSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          onSubmit(values, setSubmitting, resetForm)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          setFieldValue,
          setSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <IonCardHeader>
              <IonRow className="ion-margin-vertical ion-justify-content-between">
                <IonCol
                  size-lg="5"
                  size-md="12"
                  size="12"
                  className="order-mobile-2"
                >
                  <h3 className="Heading_3 text_sad_purple">
                    {content.get('CAT_FORM.FORM.TITLE')}
                  </h3>
                  <p className="Body_1 text_grey">
                    {content.get('CAT_FORM.FORM.DESCRIPTION')}
                  </p>
                </IonCol>
                <IonCol className="order-mobile-1">
                  <IonAvatar
                    className={`${
                      loadingProfilePic || isSubmitting
                        ? styles.avatar_loading
                        : ''
                    } ion-text-center cursor_pointer`}
                  >
                    <img
                      alt="Pet avatar image"
                      src={`${imageSrc}${
                        imageSrc?.search('base64')
                          ? ''
                          : `${imageSrc ? '&' : '?'}${moment().valueOf()}`
                      }`}
                      onClick={() => {
                        document.getElementById('profilePic').click()
                      }}
                    />
                    <div className={`${styles.edit_image_button}`}>
                      <label htmlFor="profilePic" className="cursor_pointer">
                        <IonIcon slot="start" icon={camera} />
                      </label>
                      <Field
                        type="file"
                        id="profilePic"
                        name="profilePic"
                        disabled={isSubmitting}
                        value={undefined}
                        onChange={(event) => {
                          onSubmitPP()
                          const reader = new FileReader()
                          reader.onloadstart = (e) => {
                            setFieldValue('profilePic', null)
                            setLoadingProfilePic(true)
                          }
                          reader.onloadend = (e) => {
                            setImageSrc(e.target.result)
                            setLoadingProfilePic(false)
                            setFieldValue('profilePic', event.target.files[0])
                            if (!newPet) {
                              setSubmitting(true)
                              onSubmit(
                                {
                                  ...values,
                                  profilePic: event.target.files[0]
                                },
                                setSubmitting
                              )
                            }
                          }
                          if (event.target.files.length > 0) {
                            if (
                              /image\/(jpeg|jpg|png|webp)/i.test(
                                event.target.files[0].type
                              )
                            ) {
                              reader.readAsDataURL(event.target.files[0])
                              setIsChanged(true)
                            } else {
                              setFieldValue('profilePic', '')
                              event.target.value = ''
                            }
                          }
                        }}
                      ></Field>
                    </div>
                  </IonAvatar>
                </IonCol>
              </IonRow>
            </IonCardHeader>
            <IonCardContent className={styles.card_content}>
              <IonRow>
                <IonCol size-lg="6" size-md="12" size="12">
                  <IonList className="form ion-margin-top">
                    <IonItem
                      lines="none"
                      className="ion-margin-bottom item-no-padding-left"
                    >
                      <IonLabel
                        position="stacked"
                        htmlFor="name"
                        className="Heading_5 text_sad_purple"
                      >
                        <span className="Heading_5 text_sad_purple">
                          {content.get('CAT_FORM.FORM.FIELD.INPUT')}
                        </span>
                        <span className="Lead_2 text_dark_grey ml-label-op">
                          • {content.get('CAT_FORM.FORM.FIELDS.REQUIRED_TEXT')}
                        </span>
                      </IonLabel>
                      <input
                        id="name"
                        type="name"
                        name="name"
                        className={`input w-100 ${
                          errors.name && touched.name ? 'has-error' : null
                        }`}
                        onChange={(event) => {
                          handleChange(event)
                          if (patient?.name !== event.target.value) {
                            setIsChanged(true)
                          }
                        }}
                        onBlur={(event) => {
                          handleBlur(event)
                        }}
                        placeholder={content.get('cat_form.form.field.name')}
                        value={values.name}
                      />
                      {errors.name && touched.name
                        ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label">
                            <IonIcon
                              icon={closeCircle}
                              color="danger"
                              slot="start"
                            />
                            {errors.name}
                          </div>
                        </IonItem>
                          )
                        : null}
                    </IonItem>
                  </IonList>
                </IonCol>
                <IonCol size-lg="6" size-md="12" size="12">
                  <IonList className="form ion-margin-top">
                    <IonItem
                      lines="none"
                      className="ion-margin-bottom item-no-padding-right"
                    >
                      <IonLabel position="stacked" htmlFor="email">
                        <span className="Heading_5 text_sad_purple">
                          {content.get('CAT_FORM.FORM.FIELD.GENDER')}
                        </span>
                        <span className="Lead_2 text_dark_grey ml-label-op">
                          • {content.get('CAT_FORM.FORM.FIELDS.REQUIRED_TEXT')}
                        </span>
                      </IonLabel>
                      <IonRadioGroup
                        mode="md"
                        name="gender"
                        value={values.gender}
                        onIonChange={(event) => {
                          handleChange(event)
                          if (patient?.gender !== event.target.value) {
                            setIsChanged(true)
                          }
                        }}
                        className="w-100"
                      >
                        <IonRow>
                          <IonCol size-md="6" size="12">
                            <IonItem lines="none" className="item-no-padding">
                              <div
                                className={`${styles.input_radio_wrapper} d-flex justify-content-between ion-no-padding input-radio-wrapper`}
                              >
                                <div
                                  className={`${
                                    values.gender === 'male'
                                      ? 'checkbox-selected'
                                      : ''
                                  } input-radio`}
                                >
                                  <IonRadio value="male" />
                                  <IonLabel>
                                    {content.get(
                                      'CAT_FORM.FORM.FIELD.GENDER_OPTIONS.OPTION1'
                                    )}
                                  </IonLabel>
                                </div>
                              </div>
                            </IonItem>
                          </IonCol>
                          <IonCol size-md="6" size="12">
                            <IonItem lines="none" className="item-no-padding">
                              <div
                                className={`${styles.input_radio_wrapper} d-flex justify-content-between ion-no-padding input-radio-wrapper`}
                              >
                                <div
                                  className={`${
                                    values.gender === 'female'
                                      ? 'checkbox-selected'
                                      : ''
                                  } input-radio`}
                                >
                                  <IonRadio value="female" />
                                  <IonLabel>
                                    {content.get(
                                      'CAT_FORM.FORM.FIELD.GENDER_OPTIONS.OPTION2'
                                    )}
                                  </IonLabel>
                                </div>
                              </div>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      </IonRadioGroup>
                      {errors.gender && touched.gender
                        ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label">
                            <IonIcon
                              icon={closeCircle}
                              color="danger"
                              slot="start"
                            />
                            {errors.gender}
                          </div>
                        </IonItem>
                          )
                        : null}
                    </IonItem>
                  </IonList>
                </IonCol>
                <IonCol size-lg="6" size-md="12" size="12">
                  <IonList className="form ion-margin-top">
                    <IonItem
                      lines="none"
                      className="ion-margin-bottom item-no-padding-left"
                    >
                      <IonLabel
                        position="stacked"
                        htmlFor="email"
                        className="Heading_5 text_sad_purple ion-no-margin"
                      >
                        {content.get('CAT_FORM.FORM.FIELD.DATE_BIRTH')}
                      </IonLabel>
                      <span className="Body_3 text_dark_grey ion-margin-bottom">
                        {content.get(
                          'CAT_FORM.FORM.FIELD.DATE_BIRTH_DESCRIPTION'
                        )}
                      </span>
                      {desktop || (
                        <IonDatetime
                          name="born_on"
                          placeholder="Select the birth date"
                          value={values.born_on}
                          displayFormat="MM/DD/YYYY"
                          onIonChange={(date) => {
                            setFieldValue('born_on', date.detail.value)
                            if (patient?.born_on !== date.detail.value) {
                              setIsChanged(true)
                            }
                          }}
                        ></IonDatetime>
                      )}
                      {desktop && (
                        <DateInputDesktop
                          className={`${styles.input_w_300} mb-2`}
                          dateFormatCalendar="MMMM"
                          dateOptions={{
                            startDate: values.born_on,
                            setStartDate: (date) =>
                              setFieldValue('born_on', date)
                          }}
                          format="MM/dd/yyyy"
                          label="Select a date"
                          maxDate={new Date()}
                          name="born_on"
                          onChange={(date) => {
                            setFieldValue('born_on', date)
                            if (patient?.born_on !== date) {
                              setIsChanged(true)
                            }
                          }}
                          placeholder="Select a date"
                          portal="#datepicker-portal"
                          selectRange={false}
                          selected={values?.born_on}
                          showYearDropdown
                        />
                      )}
                      {errors.born_on && touched.born_on
                        ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label">
                            <IonIcon
                              icon={closeCircle}
                              color="danger"
                              slot="start"
                            />
                            {errors.born_on}
                          </div>
                        </IonItem>
                          )
                        : null}
                    </IonItem>
                  </IonList>
                </IonCol>
                <IonCol size-lg="6" size-md="12" size="12">
                  <IonList className="form ion-margin-top">
                    <IonItem
                      lines="none"
                      className="ion-margin-bottom item-no-padding-right"
                    >
                      <IonLabel
                        position="stacked"
                        htmlFor="email"
                        className="Heading_5 text_sad_purple ion-no-margin"
                      >
                        {content.get('CAT_FORM.FORM.FIELD.DESCRIPTION')}
                      </IonLabel>
                      <span className="Body_3 text_dark_grey ion-margin-bottom">
                        {content.get(
                          'CAT_FORM.FORM.FIELD.DESCRIPTION_DESCRIPTION'
                        )}
                      </span>
                      <IonTextarea
                        name="description"
                        placeholder={content.get(
                          'CAT_FORM.FIELDS.INFORMATION_DESCRIPTION'
                        )}
                        value={values.description}
                        onIonChange={(event) => {
                          handleChange(event)
                          if (patient?.description !== event.target.value) {
                            setIsChanged(true)
                          }
                        }}
                      />
                      {errors.description && touched.description
                        ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label">
                            <IonIcon
                              icon={closeCircle}
                              color="danger"
                              slot="start"
                            />
                            {errors.description}
                          </div>
                        </IonItem>
                          )
                        : null}
                    </IonItem>
                  </IonList>
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-vertical ion-justify-content-between">
                <IonCol size-lg="5" size-md="12" size="12">
                  <h3 className="Heading_3 text_sad_purple">
                    {content.get('CAT_FORM.FIELDS.LOCATION_LABEL')}
                  </h3>
                  <p className="Body_1 text_grey">
                    {content.get('CAT_FORM.FIELDS.LOCATION_DESCRIPTION')}
                  </p>
                </IonCol>
                <IonCol size-lg="6" size-md="12" size="12">
                  <div className="ion-margin-top">
                    <div className="ion-margin-bottom ml-lg">
                      <IonLabel
                        position="stacked"
                        htmlFor="email"
                        className="Heading_5 text_sad_purple"
                      >
                        {content.get('CAT_FORM.FORM.FIELD.COUNTRY')}
                      </IonLabel>
                      <Select
                        placeholder={content.get(
                          'CAT_FORM.FORM.FIELD.COUNTRY_PLACEHOLDER'
                        )}
                        value={countrySelector}
                        options={countryList}
                        onChange={(e) => {
                          setFieldValue('country', e)
                          setCountrySelector(e)
                          setStateSelector(null)
                          setCitySelector(null)
                          defineStateList(e)
                          setIsChanged(true)
                        }}
                      />
                      {errors.description && touched.description
                        ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label">
                            <IonIcon
                              icon={closeCircle}
                              color="danger"
                              slot="start"
                            />
                            {errors.country}
                          </div>
                        </IonItem>
                          )
                        : null}
                    </div>
                    <div className="ion-margin-bottom ml-lg">
                      <IonLabel
                        position="stacked"
                        htmlFor="email"
                        className="Heading_5 text_sad_purple"
                      >
                        {content.get('CAT_FORM.FORM.FIELD.STATE')}
                      </IonLabel>
                      <Select
                        placeholder={content.get(
                          'CAT_FORM.FORM.FIELD.STATE_PLACEHOLDER'
                        )}
                        value={stateSelector}
                        disabled={!countrySelector}
                        options={stateList}
                        onChange={(e) => {
                          setFieldValue('state', e)
                          setStateSelector(e)
                          setCitySelector(null)
                          defineCityList(countrySelector, e)
                          setIsChanged(true)
                        }}
                      />
                      {errors.description && touched.description
                        ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label">
                            <IonIcon
                              icon={closeCircle}
                              color="danger"
                              slot="start"
                            />
                            {errors.state}
                          </div>
                        </IonItem>
                          )
                        : null}
                    </div>
                    <div className="ion-margin-bottom ml-lg">
                      <IonLabel
                        position="stacked"
                        htmlFor="email"
                        className="Heading_5 text_sad_purple"
                      >
                        {content.get('CAT_FORM.FORM.FIELD.CITY')}
                      </IonLabel>
                      <Select
                        placeholder={content.get(
                          'CAT_FORM.FORM.FIELD.CITY_PLACEHOLDER'
                        )}
                        value={citySelector}
                        disabled={!stateSelector || !countrySelector}
                        options={cityList}
                        onChange={(e) => {
                          setFieldValue('city', e)
                          setCitySelector(e)
                          setIsChanged(true)
                        }}
                      />
                      {errors.description && touched.description
                        ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label">
                            <IonIcon
                              icon={closeCircle}
                              color="danger"
                              slot="start"
                            />
                            {errors.city}
                          </div>
                        </IonItem>
                          )
                        : null}
                    </div>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-vertical ion-justify-content-between">
                <IonCol size-lg="5" size-md="12" size="12">
                  <h3 className="Heading_3 text_sad_purple">
                    {content.get('CAT_FORM.FIELDS.ORIGIN_LABEL')}
                  </h3>
                  <p className="Body_1 text_grey">
                    {content.get('CAT_FORM.FIELDS.ORIGIN_DESCRIPTION')}
                  </p>
                </IonCol>
                <IonCol size-lg="6" size-md="12" size="12">
                  <IonList className="form ion-margin-top">
                    <IonItem
                      lines="none"
                      className="ion-margin-bottom item-no-padding-right"
                    >
                      <IonLabel
                        position="stacked"
                        htmlFor="email"
                        className="Heading_5 text_sad_purple"
                      >
                        {content.get('CAT_FORM.FORM.FIELD.HOW_ADOPTED')}
                      </IonLabel>
                      <IonRadioGroup
                        mode="md"
                        name="adopted"
                        value={values.adopted}
                        className={`${styles.radio_group} w-100`}
                        onIonChange={(e) => {
                          handleChange(e)
                          if (
                            e.detail.value !== patient?.birth_location?.adopted
                          ) {
                            setIsChanged(true)
                          }
                        }}
                      >
                        {adoptedOptions.map((option, index) => (
                          <IonItem
                            lines="none"
                            key={index}
                            className="item-no-padding ion-margin-bottom"
                          >
                            <div
                              className={`${styles.input_radio_wrapper} d-flex justify-content-between ion-no-padding input-radio-wrapper`}
                            >
                              <div
                                className={`${
                                  values.adopted === option.id
                                    ? 'checkbox-selected'
                                    : ''
                                } input-radio`}
                              >
                                <IonRadio value={option.id} />
                                <IonLabel>{content.get(option.label)}</IonLabel>
                              </div>
                            </div>
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                      {errors.adopted && touched.adopted
                        ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label">
                            <IonIcon
                              icon={closeCircle}
                              color="danger"
                              slot="start"
                            />
                            {errors.adopted}
                          </div>
                        </IonItem>
                          )
                        : null}
                    </IonItem>
                  </IonList>
                </IonCol>
              </IonRow>
            </IonCardContent>
            <div id="footer" className={styles.card_footer}>
              <EditableIonButton
                mode="md"
                color="dark"
                fill="outline"
                shape="round"
                onClick={resetForm}
                className="default_button"
              >
                <IonLabel>{content.get('CAT_FORM.BUTTON.DISCARD')}</IonLabel>
              </EditableIonButton>
              <LoaderButton
                type="submit"
                disabled={isSubmitting || !isChanged}
                color="primary"
                className="default_button"
                isSubmitting={isSubmitting}
              >
                {content.get('CAT_FORM.BUTTON.SUBMIT')}
              </LoaderButton>
            </div>
            <div
              id="footerFixed"
              className={`${styles.card_footer} footer_fixed`}
            >
              <IonGrid fixed>
                <IonRow className="ion-justify-content-center">
                  <IonCol size-md="10" size="11">
                    <div className="d-flex ion-justify-content-between ml-2 mr-2">
                      <EditableIonButton
                        mode="md"
                        color="dark"
                        fill="outline"
                        shape="round"
                        onClick={resetForm}
                        className="default_button"
                      >
                        <IonLabel>
                          {content.get('CAT_FORM.BUTTON.DISCARD')}
                        </IonLabel>
                      </EditableIonButton>
                      <LoaderButton
                        type="submit"
                        disabled={isSubmitting || !isChanged}
                        color="primary"
                        className="default_button"
                        isSubmitting={isSubmitting}
                      >
                        {content.get('CAT_FORM.BUTTON.SUBMIT')}
                      </LoaderButton>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </form>
        )}
      </Formik>
    </>
  )
}

export default CatForm
