import React, { useState } from 'react'
import { useHistory } from 'react-router'

import {
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonInput,
  IonLabel,
  IonIcon,
  IonListHeader,
  IonCheckbox,
  IonButton
} from '@ionic/react'
import {
  closeCircle,
  eye,
  eyeOff,
  checkmarkCircle,
  ellipseOutline,
  logoFacebook,
  logoGoogle
} from 'ionicons/icons'
import content from 'util/content'

import { Formik } from 'formik'
import * as Yup from 'yup'
import services from 'util/services'
import styles from './RegisterForm.module.scss'
import LoaderButton from 'components/basepaws/utils/LoaderButton/index'
import EditableNavLink from 'components/basepaws/utils/EditableNavLink'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'

const RegisterForm = () => {
  const [passwordFieldType, setPasswordFieldType] = useState({
    password: 'password',
    passwordIcon: eye,
    passwordRepeat: 'password',
    passwordRepeatIcon: eye
  })

  const history = useHistory()

  const changeType = (field) => {
    const currentType = passwordFieldType[field]
    let nextType = 'password'
    let icon = eye
    if (currentType === 'password') {
      nextType = 'text'
      icon = eyeOff
    }
    setPasswordFieldType({
      ...passwordFieldType,
      [field]: nextType,
      [`${field}Icon`]: icon
    })
  }

  const onSubmit = async (values, setSubmitting) => {
    try {
      await services.post('/auth/register', values)
      services.post('/auth/login', registerValues)
      history.push('/profile')
    } catch (e) {
      console.log(e)
      setSubmitting(false)
      setMailNew(false)
    }
    setSubmitting(false)
  }

  const [isMailNew, setMailNew] = useState(true)

  const [registerValues, setRegisterValues] = useState({
    email: '',
    password: ''
  })

  const passwordRequiredError = content.get('REGISTER.FORM.ERROR.PASSWORD')
  const passwordMatchError = content.get('REGISTER.FORM.ERROR.TITLE')

  const passwordValidator = (value) => {
    const errors = {
      hasUpperCase: true,
      hasNumber: true,
      hasMinLength: true
    }

    if (!value || typeof value !== 'string') {
      return errors
    }
    if (!/[A-Z]/.test(value)) {
      errors.hasUpperCase = false
    }
    if (!/\d/.test(value)) {
      errors.hasNumber = false
    }
    if (value.length < 6) {
      errors.hasMinLength = false
    }

    return errors
  }

  const registerSchema = Yup.object({
    first_name: Yup.string().required(
      content.get('REGISTER.FORM.ERROR.FIRSTNAME')
    ),
    last_name: Yup.string().required(
      content.get('REGISTER.FORM.ERROR.LASTNAME')
    ),
    email: Yup.string()
      .email(content.get('REGISTER.FORM.ERROR.EMAIL_VALID'))
      .required(content.get('REGISTER.FORM.ERROR.EMAIL')),
    password: Yup.string()
      .test('passwordValidator', null, function (value) {
        const errors = passwordValidator(value)
        const isValid =
          errors.hasUpperCase && errors.hasNumber && errors.hasMinLength
        return (
          isValid ||
          this.createError({ message: 'Password does not meet requirements' })
        )
      })
      .required(passwordRequiredError),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), undefined], passwordMatchError)
      .required(content.get('REGISTER.FORM.ERROR.PASSWORD_CONFIRMATION')),
    term: Yup.bool().oneOf([true]),
    privacy: Yup.bool().oneOf([true])
  })

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        password: '',
        password_confirmation: '',
        term: false,
        privacy: false
      }}
      isInitialValid={false}
      validationSchema={registerSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting)
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid
      }) => {
        const passwordErrors = passwordValidator(values.password)
        return (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (
                e.key === 'Enter' &&
                values.first_name !== '' &&
                values.phone_number !== '' &&
                values.last_name !== '' &&
                values.email !== '' &&
                values.password !== '' &&
                values.password_confirmation !== ''
              ) {
                handleSubmit()
              }
            }}
          >
            <IonRow className="ion-justify-content-center">
              <IonCol size="12">
                <div className={styles.social_media_wrapper}>
                  <IonButton
                    mode="md"
                    color="primary"
                    size="full"
                    fill="outline"
                    shape="round"
                    className="default_button"
                    href="/2020-09/auth/facebook"
                  >
                    <IonIcon slot="start" icon={logoFacebook}></IonIcon>
                    {content.get('REGISTER.FORM.BUTTONS.FACEBOOK')}
                  </IonButton>
                  <IonButton
                    mode="md"
                    color="tertiary"
                    size="full"
                    fill="outline"
                    shape="round"
                    className="default_button"
                    href="/2020-09/auth/google"
                  >
                    <IonIcon slot="start" icon={logoGoogle}></IonIcon>
                    {content.get('REGISTER.FORM.BUTTONS.GOOGLE')}
                  </IonButton>
                  <div className="d-flex ion-justify-content-center">
                    <hr />
                    <p className="Body_1">
                      {content.get('REGISTER.FORM.ALTERNATIVE_SIGNUP_MESSAGE')}
                    </p>
                    <hr />
                  </div>
                </div>
                <IonList className="form">
                  <IonItem lines="none" className="item-no-padding">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">
                          {content.get('REGISTER.FORM.FIRSTNAME_LABEL')}
                        </h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem
                    lines="none"
                    className="ion-margin-bottom item-no-padding"
                  >
                    <IonInput
                      type="text"
                      name="first_name"
                      required
                      className={`input-w-icon ${
                        errors.first_name ? 'has-error' : null
                      }`}
                      clearOnEdit={false}
                      onIonChange={handleChange}
                      placeholder={content.get(
                        'REGISTER.FORM.FIRSTNAME_PLACEHOLDER',
                        [],
                        true
                      )}
                    ></IonInput>
                    {errors.first_name && (
                      <IonItem className="ion-no-margin item-no-padding input-error-message input-error-absolute">
                        <div className="input-error-label">
                          <IonIcon
                            icon={closeCircle}
                            color="danger"
                            slot="start"
                          />
                          {errors.first_name}
                        </div>
                      </IonItem>
                    )}
                  </IonItem>
                  <IonItem lines="none" className="item-no-padding">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">
                          {content.get('REGISTER.FORM.LASTNAME_LABEL')}
                        </h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem
                    lines="none"
                    className="ion-margin-bottom item-no-padding"
                  >
                    <IonInput
                      type="text"
                      name="last_name"
                      required
                      clearOnEdit={false}
                      className={`input-w-icon ${
                        errors.last_name ? 'has-error' : null
                      }`}
                      onIonChange={handleChange}
                      placeholder={content.get(
                        'REGISTER.FORM.LASTNAME_PLACEHOLDER',
                        [],
                        true
                      )}
                    ></IonInput>
                    {errors.last_name && (
                      <IonItem className="ion-no-margin item-no-padding input-error-message input-error-absolute">
                        <div className="input-error-label">
                          <IonIcon
                            icon={closeCircle}
                            color="danger"
                            slot="start"
                          />
                          {errors.last_name}
                        </div>
                      </IonItem>
                    )}
                  </IonItem>
                  <IonItem lines="none" className="item-no-padding">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">
                          {content.get('REGISTER.FORM.EMAIL_LABEL')}
                        </h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem
                    lines="none"
                    className="ion-margin-bottom item-no-padding"
                  >
                    <IonInput
                      className={
                        !isMailNew || errors.email ? 'has-error' : null
                      }
                      type="email"
                      name="email"
                      required
                      onIonChange={handleChange}
                      placeholder={content.get(
                        'REGISTER.FORM.EMAIL_PLACEHOLDER',
                        [],
                        true
                      )}
                      onIonFocus={() => setMailNew(true)}
                      onIonInput={(event) => {
                        setMailNew(true)
                        setRegisterValues({
                          ...registerValues,
                          email: event.target.value
                        })
                        handleChange(event)
                      }}
                    ></IonInput>
                    {errors.email && (
                      <IonItem className="ion-no-margin item-no-padding input-error-message input-error-absolute">
                        <div className="input-error-label">
                          <IonIcon
                            icon={closeCircle}
                            color="danger"
                            slot="start"
                          />
                          {errors.email}
                        </div>
                      </IonItem>
                    )}
                  </IonItem>
                  <IonItem lines="none" className="item-no-padding">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">
                          {content.get('REGISTER.FORM.PASSWORD_LABEL')}
                        </h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem lines="none" className="item-no-padding">
                    <IonInput
                      placeholder="Password"
                      className={`input-w-icon ${
                        errors.password ? 'has-error' : null
                      }`}
                      type={passwordFieldType.password}
                      name="password"
                      onIonChange={handleChange}
                      clearOnEdit={false}
                      onIonInput={(event) => {
                        setRegisterValues({
                          ...registerValues,
                          password: event.target.value
                        })
                        handleChange(event)
                      }}
                    ></IonInput>
                    <IonButton
                      className="showPasswordEyeBtn"
                      mode="md"
                      slot="end"
                      fill="clear"
                      tabindex="-1"
                      onClick={() => changeType('password')}
                    >
                      <IonIcon
                        slot="icon-only"
                        color="dark"
                        icon={passwordFieldType.passwordIcon}
                      ></IonIcon>
                    </IonButton>
                  </IonItem>
                  <IonItem className="ion-margin-bottom item-no-padding">
                    <IonRow className="w-100">
                      <IonCol size="auto">
                        <EditableIonButton
                          mode="md"
                          className="button-form-check"
                          size="small"
                          fill="clear"
                          disabled
                        >
                          <IonIcon
                            slot="start"
                            icon={
                              !values.password
                                ? ellipseOutline
                                : passwordErrors.hasUpperCase
                                  ? checkmarkCircle
                                  : closeCircle
                            }
                            color={
                              !values.password
                                ? undefined
                                : passwordErrors.hasUpperCase
                                  ? 'success'
                                  : 'danger'
                            }
                          />
                          {content.get('REGISTER.FORM.PASSWORD_STRENGHT1')}
                        </EditableIonButton>
                      </IonCol>
                      <IonCol size="auto">
                        <EditableIonButton
                          mode="md"
                          className="button-form-check ml-2"
                          size="small"
                          fill="clear"
                          disabled
                        >
                          <IonIcon
                            slot="start"
                            icon={
                              !values.password
                                ? ellipseOutline
                                : passwordErrors.hasNumber
                                  ? checkmarkCircle
                                  : closeCircle
                            }
                            color={
                              !values.password
                                ? undefined
                                : passwordErrors.hasNumber
                                  ? 'success'
                                  : 'danger'
                            }
                          />
                          {content.get('REGISTER.FORM.PASSWORD_STRENGHT2')}
                        </EditableIonButton>
                      </IonCol>
                      <IonCol size="auto">
                        <EditableIonButton
                          mode="md"
                          className="button-form-check ml-2"
                          size="small"
                          fill="clear"
                          disabled
                        >
                          <IonIcon
                            slot="start"
                            icon={
                              !values.password
                                ? ellipseOutline
                                : passwordErrors.hasMinLength
                                  ? checkmarkCircle
                                  : closeCircle
                            }
                            color={
                              !values.password
                                ? undefined
                                : passwordErrors.hasMinLength
                                  ? 'success'
                                  : 'danger'
                            }
                          />
                          {content.get('REGISTER.FORM.PASSWORD_STRENGHT3')}
                        </EditableIonButton>
                      </IonCol>
                    </IonRow>
                  </IonItem>
                  {/* Valitation: Checks chraracters, numbers, etc. */}
                  <IonItem lines="none" className="item-no-padding">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">
                          {content.get('REGISTER.FORM.CONFIRMPASSWORD_LABEL')}
                        </h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem
                    lines="none"
                    className="ion-margin-bottom item-no-padding"
                  >
                    <IonInput
                      placeholder={content.get(
                        'REGISTER.FORM.CONFIRMPASSWORD_PLACEHOLDER',
                        [],
                        true
                      )}
                      className={`input-w-icon ${
                        errors.password_confirmation ? 'has-error' : null
                      }`}
                      type={passwordFieldType.passwordRepeat}
                      name="password_confirmation"
                      clearOnEdit={false}
                      onIonChange={handleChange}
                    ></IonInput>
                    <IonButton
                      className="showPasswordEyeBtn"
                      mode="md"
                      slot="end"
                      fill="clear"
                      tabindex="-1"
                      onClick={() => changeType('passwordRepeat')}
                    >
                      <IonIcon
                        slot="icon-only"
                        color="dark"
                        icon={passwordFieldType.passwordRepeatIcon}
                      ></IonIcon>
                    </IonButton>
                    {errors.password_confirmation && (
                      <IonItem className="ion-no-margin item-no-padding input-error-message input-error-absolute">
                        <IonRow>
                          <IonCol>
                            <div className="input-error-label">
                              <IonIcon
                                icon={closeCircle}
                                color="danger"
                                slot="start"
                              />
                              {errors.password_confirmation}
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonItem>
                    )}
                  </IonItem>
                  {/* Valitation: Both password should match */}
                  <hr className={styles.divider} />
                  <IonItem lines="none" className="item-no-padding">
                    <p className="Body_2 ion-no-margin">
                      {content.get('REGISTER.FORM.TERMS_TEXT')}
                      <a
                        href="https://basepaws.com/pages/terms-of-service"
                        className="ml-2 Body_2_bold"
                      >
                        {content.get('REGISTER.FORM.TERMS_LINK_TEXT')}
                      </a>
                      .
                    </p>
                    <IonCheckbox
                      required
                      name="term"
                      slot="start"
                      color="primary"
                      onIonChange={(event) => {
                        handleChange({
                          target: { name: 'term', value: event.detail.checked }
                        })
                      }}
                    />
                  </IonItem>
                  <IonItem lines="none" className="item-no-padding">
                    <p className="Body_2 ion-no-margin">
                      {content.get('REGISTER.FORM.TERMS_TEXT2')}
                      <a
                        href="https://basepaws.com/pages/privacy-policy"
                        className="ml-2 Body_2_bold"
                      >
                        {content.get('REGISTER.FORM.TERMS_LINK_TEXT2')}
                      </a>
                      .
                    </p>
                    <IonCheckbox
                      required
                      name="privacy"
                      slot="start"
                      color="primary"
                      value={values.privacy}
                      onIonChange={(event) => {
                        handleChange({
                          target: {
                            name: 'privacy',
                            value: event.detail.checked
                          }
                        })
                      }}
                    />
                  </IonItem>
                </IonList>
              </IonCol>
              {!isMailNew && (
                <IonItem className="ion-margin-bottom item-no-padding input-error-message">
                  <IonRow>
                    <IonCol>
                      <div className="input-error-label">
                        <IonIcon
                          icon={closeCircle}
                          color="danger"
                          slot="start"
                        />
                        {content.get('REGISTER.FORM.ERROR.EMAIL_EXIST')}
                      </div>
                    </IonCol>
                  </IonRow>
                </IonItem>
              )}
            </IonRow>
            <div className={`${styles.card_footer} ion-justify-content-center`}>
              <LoaderButton
                type="submit"
                disabled={isSubmitting || !isValid}
                color="primary"
                className="big_button"
                isSubmitting={isSubmitting}
              >
                Sign up
              </LoaderButton>
            </div>
            <hr className={styles.divider} />
            <p className="ion-text-center Body_1">
              {content.get('REGISTER.FORM.QUESTION_TEXT')}
              <EditableNavLink to="/login" className="ml-2">
                {content.get('REGISTER.FORM.QUESTION_LINK')}
              </EditableNavLink>
            </p>
          </form>
        )
      }}
    </Formik>
  )
}

export default RegisterForm
